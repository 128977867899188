import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import './Header.scss'
import { ReactComponent as Logo } from '../../assets/CTLogo_small.svg'
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
// import { queryCache } from 'react-query'
import { useSelector, useDispatch } from 'react-redux'
import { loggedIn as isLoggedIn } from 'utils/axios'

export default function Header(props) {
	// const user = queryCache.getQueryData('user')
	const [fixed, setFixed] = useState('static')
	const [loginAvailable, setLoginAvailable] = useState(props?.loginAvailable ?? true)
	// const { loggedIn } = useSelector(state => state.app)
	const loggedIn = isLoggedIn()
	// const token = tokenQuery.useToken()
	// console.log('token', token)
	// const loggedIn = token?.userId ? true : false
	const dispatch = useDispatch()
	const handleScroll = useCallback(
		(event) => {
			// let scrollTop = event.srcElement.body.scrollTop,
			// 	itemTranslate = Math.min(0, scrollTop / 3 - 60);

			var distanceY = window.pageYOffset || event.currentTarget.scrollTop;
			var shrinkOn = 100;
			if (distanceY > shrinkOn)
				setFixed('fixed')
			else
				setFixed('static')
			// setState({
			//   transform: itemTranslate
			// });

			/*
			function scrollShrink(e) {

				var header = $("header");
				// console.log(distanceY);
				if (distanceY > shrinkOn) {
					header.addClass("fixed");
				} else {
					if (header.hasClass("fixed")) {
						header.removeClass("fixed");
					}
				}
			}
			*/
		},
		[],
	)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [handleScroll])

	return (
		<div className={`${fixed} header`} style={fixed === 'fixed' ? GS.boxShadow : {}}>
			<div className='nav'>
				<a href="/"><Logo className='logo' /></a>
				<div className='space-between' />
				{ loginAvailable && <div className="menu-wrapper">
					{ (!loggedIn)
						? <>
								<Link to="/register" >Sign Up</Link>
							</>
						: <>
								<a href="/logout" onClick={(e) => {
									e.preventDefault()
									dispatch({type:"SAGA_LOGOUT"})
								}}>Log out</a>
								<Link to="/profile" >App</Link>
							</>
					}
					<div className='hamberger'/>
				</div> }
			</div>
		</div>
	)
}
